/* Body and Background Image */
body {
  background-color: rgb(33, 37, 41);
  font-family: Verdana, Tahoma, sans-serif;
}

.background-image {
  background-image: url("images/DSCN0110.JPG");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}

@media (max-width: 899px) {
  .background-image {
    background-image: none;
  }

  .sm-background-color {
    background-color: rgba(33, 37, 41, .75);
  }

  .sm-background-image {
    background-image: url("images/DSCN0110.JPG");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

/* Aspect Ratio Box */
/* @media (max-width: 767px){
  .aspectRatioBox {
    padding-top: calc(3/7 * 100%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
  }
} */

.aspectRatioBox {
  padding-top: calc(2/3 * 100%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  transition: opacity 1s ease;
}

/* .aspectRatioBox:hover{
  transform: scale(1.05);
  transition: 0.5s linear;
} */

.left {
  background-position: left;
}


/* Nav Bar */
.nav-item:hover {
  text-decoration: underline;
  /* border-bottom: 1px solid white; */
  transform: scale(1.1) translate(0, -3px);
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:active,
.navbar-toggler-icon:focus,
.navbar-toggler:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.semi-transparent {
  background-color: rgba(69, 73, 78, 0.75);
}

.custom-toggler-icon {
  background-image: none; /* Remove the default background image */
}

.navbar-toggler:hover {
  transform: scale(1.2);
  transition: 0.2s linear;
}

.custom-toggler-icon::before {
  content: '\2630';
  color: white;
  font-size: 2rem;
}

/* Heading */
@media (min-width: 768px) {
  .heading {
    text-align: center;
  }
}

.site-links .icon:hover {
  transform: scale(1.3) translate(0, -1px);
}

@media (max-width: 899px){
  .info h5 {
    font-size: 1.1rem;
  }
}

/* Skills */
@media (min-width: 900px) {
  #skills li {
    font-size: 1.05rem;
  }
}

@media (max-width: 899px) {
  #skills li {
    font-size: 1rem;
  }

  #skills h2 {
    font-size: 1.4rem;
  }

  .resume {
    background: linear-gradient(grey,#212529);
  }
}

@media (max-width: 767px) {
  #skills li {
    font-size: 0.9rem;
  }
}

/* Education & Experience */
@media (max-width: 899px) {
  .education h2,
  .experience h2 {
    font-size: 1.4rem;
  }

  .education h4,
  .experience h4 {
    font-size: 1.3rem;
  }
  .education h5,
  .experience h5 {
    font-size: 1rem;
  }
  .education p,
  .experience p {
    font-size: 0.9rem;
  }
}

.education h5:hover,
.experience h5:hover {
  transform: translate(0, -1px);
  text-decoration: underline;
}

/* Projects */
@media (min-width: 900px){
  .project-content h6 {
    font-size: 1.2rem;
  }

  #projects h4 {
    font-size: 1.5rem;
  }

  #softwares h6 {
    font-size: 1rem;
  }
}

@media (max-width: 899px) {
  .project-content h6 {
    font-size: 1.1rem;
  }

  .project-content p {
    font-size: 15px;
  }

  #projects h4 {
    font-size: 1.4rem;
  }

  #softwares h6{
    font-size: 0.9rem;
  }
}

@media (max-width: 767px) {
  .project-content h6 {
    font-size: 1rem;
  }

  .project-content p {
    font-size: 14px;
  }

  #projects h4 {
    font-size: 1.2rem;
  }

  #softwares h6 {
    font-size: 0.8rem;
  }
}

.projects-background {
  /* background-color: gray; */
  background: linear-gradient(#212529, grey);
}

#projects {
    color: white;
}

.project-content h6 {
  text-overflow: ellipsis;
}

.project-content h6:hover{
  /* color:rgb(200, 200, 200); */
  transform: translateY(-2px);
  transition: 0.2s linear;
}

.project-buttons {
  opacity: 0 !important;
  transition: opacity 1s ease;
}

/* .project-link {
  white-space: nowrap;
}

@media (max-width: 675px) {
  .project-link {
    font-size: 12px !important;
  }
}
@media (max-width: 583px) {
  .project-link {
    font-size: 10px !important;
  }
} */

.project-container:hover .project-buttons {
  opacity: 1 !important;
}

.project-container:hover .aspectRatioBox {
  opacity: 0.5 !important;
}

.project-container:hover, .cert-container .aspectRatioBox:hover {
  transform: scale(1.05);
  transition: 0.5s linear;
}

@media (max-width: 576px) {
  .project-row-btns .btn {
    font-size: 0.75rem;
  }
}

@media (max-width: 425px) {
  .project-row-btns .btn {
    font-size: 0.7rem;
  }
}

/* Footer */
footer a {
  font-size: 0.9rem;
}

footer a:hover {
  transform: translate(0, -1px);
  text-decoration: underline !important;
}